// import * as $ from 'jquery'
//import '@popperjs/core';
// import 'bootstrap';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
//import $ from "expose-loader?exposes=$,jQuery!jquery";
//import { jQuery } from 'jquery';
//window.jQuery = $;
//window.$ = $;
//import 'select2'
//import 'jquery-ajax-unobtrusive';
//import '../wwwroot/js/site.js'


//import 'bootstrap/dist/css/bootstrap.css';
console.log('The bundle has been loaded!');
//console.log(jQuery)

import LazyLoad from "vanilla-lazyload";

const logEvent = (eventName, element) => {
	console.log(
		Date.now(),
		eventName,
		element.getAttribute("data-src"),
		element.getAttribute("src")
	);
};

const lazyLoadOptions = {
	elements_selector: ".lazy",
	to_webp: true,

	//callback_enter: element => {
		//logEvent("ENTERED", element);
	//},
	//callback_load: element => {
	//	logEvent("LOADED", element);
	//},
	//callback_set: element => {
	//	logEvent("SET", element);
	//},
	callback_error: element => {
		//logEvent("ERROR", element);
		element.src = "/images/previewna.png";
	}
};

const createLazyLoadInstance = () => {
	console.log("loading lazy DOM");
	return new LazyLoad(lazyLoadOptions);
};

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);
